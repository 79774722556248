#counter {
	display: flex;
	justify-content: center;
	position: fixed;
	width: 100%;
	bottom: 0;
	flex-direction: column;
	background:
    url('../../public/images/bottom-left.png') fixed bottom -6.5em left, /*-15.5vh*/
    url('../../public/images/bottom-right.png') fixed bottom -5.5em right, /*-12vh*/
    white;
    background-repeat: no-repeat;
  	background-size: 26.6em; /*54vh*/
}

#counter ul {
	list-style-type: none;
	flex-direction: row;
	display: flex;
	justify-content: center;
	padding: 0;
}

li#counter-list {
	margin: 0 1.25em;
	font-size: 16px;
	text-align: center;
}

.time-value, #counter-title {
	font-family: regus-brown;
	font-size: 32px;
}

#counter-title {
	display: flex;
	justify-content: center;
	margin-bottom: 0.3em;
}

@media screen and (max-width: 480px) {
	li#counter-list {
		font-size: 16px;
	}

	.time-value, #counter-title {
		font-size: 24px;
	}

	#counter {
		background:
	    url('../../public/images/bottom-left.png') fixed bottom -3em left, /*-6vh*/
	    url('../../public/images/bottom-right.png') fixed bottom -2.5em right, /*-5vh*/
	    white;
	    background-repeat: no-repeat;
	  	background-size: 25vh;
	  	background-attachment: scroll;
	  	z-index: -1;
	  	position: absolute;
	    bottom: 0;
	}

	#counter ul {
		margin-bottom: 4em;
	}
}