ul#language-selection {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding-top: 0.3em;
	padding-left: 0;
}

li.language-selection-list {
	float: left;
	margin: 0 0.7em;
	font-size: 24px;
}

button.languageButton {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}