#qr-icon {
    max-height: 1.5em;
    margin-right: 1em;
}

#qr-display {
    display: none;
}

#login {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 2em;
	padding-bottom: 25vh;
}

#login button {
	background-color: #CBDDBF;
	border: 2px solid #AFCC96;
	border-radius: 0.9em;
	padding: 0.7em 1.8em;
	cursor: pointer;
}

#login button:hover {
	background-color: #AFCC96;
}

#login p {
	margin: 1.25em;
}

#qr-login button {
	display: flex;
}

#code-input {
	padding: 0.8em 1.8em;
	border: 2px solid #AFCC96;
	border-radius: 0.9em;
	margin-right: 0.5em;
}

#code-input:focus {
	outline: none;
}

@media screen and (max-width: 480px) {
	#login {
		margin-bottom: 5em; /*25vh*/
	}

	#login button{
		font-size: 14px;
	}

	#code-input {
	    font-size: 14px;
	    width: 12em;
	}
}