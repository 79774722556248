.paragraph {
	width: 60vw;
}

#text-block {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 25vh; /*150px*/
	flex-direction: column;
}

@media screen and (max-width: 480px) {
	.paragraph {
		width: 90vw;
	}
}