#gang {
	padding-bottom: 25vh; /*150px*/
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#guest {
	grid-template-columns: repeat(3, auto);
	flex-direction: row;
    display: grid;
    align-items: center;
	justify-content: center;
	padding: 0;
}

.guest-name {
	font-weight: 500;
	font-size: 18px;

}

.guest-list {
	padding: 0 50px;
	list-style: none;
}

#info {
	width: 60vw;
}

#intro {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#submit-button {
	background-color: #CBDDBF;
	border: 2px solid #AFCC96;
	border-radius: 0.9em;
	padding: 0.7em 1.8em;
	cursor: pointer;
}

#submit-button:hover {
	background-color: #AFCC96;
}

#submit-button.dirty {
    background: #F6BFD4;
    border: 2px solid #F49DC0;
}

input[type="text"] {
	border: transparent;
	border-bottom: 2px solid #AFCC96;
	/*border-radius: 0.9em;*/
	/*padding: 0.6em 1.2em;*/
	margin-left: 1em;
	font-size: 16px;
	width: fit-content;
	min-width: 12em;
}

.form-part {
	margin-bottom: 1em;
	margin-top: 1em;
}

input[type="radio"] {
	margin-left: 1em;
	accent-color: forestgreen;
	opacity: 60%
}

#submit-button {
	margin: 0;
	position: relative;
	left: 45%;
}

@media screen and (max-width: 480px) {
	.guest-list {
		width: 85vw;
		max-width: 100%;
		margin-left: 1em;
	}

	#guest {
		margin-bottom: 1em;
		grid-template-columns: repeat(1, auto);
		flex-direction: column;
		padding: 0;
	}

	#gang {
    	align-items: center;
		justify-content: center;
		margin: 0;
		width: 90vw; /*100vw*/
	}

	#info {
		width: 90vw;
	}

	input[type="submit"]#submit-button {
	    font-size: 14px;
	    width: 7em;
	    height: 3em;
	    border-radius: 0.9em !important;
	    /*left: 40%*/
	}

	input[type="text"].form-part {
		/*margin-left: 1em;*/
		margin-bottom: 1em;
		border: 2px solid #AFCC96;
		/*border-radius: 0.9em;*/
		padding: 0.6em 1.2em;
	}
}