#names {
	font-family: 'regus-brown';
	font-size: 48px;
	margin: 0.5em; 
}

#header {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
}

#top {
	background: 
	url('../../public/images/top-left.png') fixed top -2em left, /*-5.5vh*/
	url('../../public/images/top-right.png') fixed top -5em right, /*-12.5vh*/
	white;
	background-repeat: no-repeat;
	background-size: 28em;
	position: sticky;
	top: 0;
	margin: 0;
}

#date {
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 0.5em;
}

@media screen and (max-width: 480px) {
	#names {
		font-size: 36px;
	}

	#header {
		line-height: 1em;
		padding-top: 18px;
	}

	#top {
		background: 
			url('../../public/images/top-left.png') fixed top -1.5em left, /*-4vh*/
			url('../../public/images/top-right.png') fixed top -4em right, /*-10.5vh*/
			white;
		background-repeat: no-repeat;
		background-size: 30vh;
		background-attachment: scroll;
		z-index: 2;
	}
}