ul#navigation {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	padding-bottom: 0.4em;
}

nav a {
	margin: 0 1.5em;
	text-decoration: none;
	color: #262625;
}

nav a:hover, .active-link  {
    border-bottom: 2px solid #CBDDBF;
}

.navBar button {
	display: none;
}

/* sidebar Nav */
@media screen and (max-width: 960px) {

	ul#navigation {
		list-style-type: none;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0;
		margin: 0;
}

	.navBar button {
		display: block;
		color: #262625;
		position: fixed; 
		right: 4px;
		top: 90px;
		z-index: 10;
		cursor: pointer;
		font-size: 24px;
		border-color: transparent;
		padding: 5px 15px;
		background: transparent;
	}

	.menuNav {
		overflow-y: scroll;
		list-style: none;
		position: fixed;
		top: 0;
		background: #CBDDBF;
		left: 0;
		bottom: 0;
		height: 100vh;
		width: 0;
		overflow: hidden;
		max-width: 290px;
	}

	.menuNav.showMenu {
		width: 100%;
	}

	nav a {
		display: block;
		padding: 10px 40px;
		text-decoration: none;
		margin-bottom: 1em;
	}

	.active-link {
		border-bottom: 2px solid white;
		line-height: 0.7em;
		color: #ffffff;
		font-weight: 500;
	}
}