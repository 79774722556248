#photo-info {
	display: flex;
	align-items: center;
	justify-content: center;
}

ul.photo-list {
	list-style: none;
	column-count: 3;
	padding-left: 10vw;
	padding-right: 10vw;
}

li img {
	max-width: 100%;
}

@media screen and (max-width: 1200px) {
	#photo-info {
		width: 90vw;
		margin: auto;
		text-align: center;
	}

	ul.photo-list {
		column-count: 1;
		padding-left: 5vw;
		padding-right: 5vw;
	}

}