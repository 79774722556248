#home-text-block {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 25vh; /*150px*/
	/*flex-direction: column;*/
	margin-top: 2em;
}

#the-paragraphs, .hero {
  	width: 50%;
    float: left;
    padding: 0 20px;
}

.home-paragraph {
	width: 50vw;
}

img.hero {
	width: 30vw;
}

@media screen and (max-width: 860px){
	#home-text-block {
		display: flex;
		flex-direction: column;
	}

	#the-paragraphs, .home-paragraph {
		width: 90vw;
	  	max-width: 100%;
	    padding: 0;
	}

	img.hero {
	  width: 100vw;
	  padding-bottom: 3vh;
	}
}

@media screen and (max-width: 480px) {
	#home-text-block {
		margin-bottom: 1em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#the-paragraphs, .home-paragraph {
	  	width: 90vw;
	  	max-width: 100%;
	    padding: 0;
	}

	img.hero {
	  width: 90vw;
	  padding-bottom: 3vh;
	}
}