@font-face {
    font-family: regus-brown;
    src: url('../public/fonts/RegusBrown-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

body, button, input {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  color: #262625;
  background: transparent;
}

#title {
  font-family: regus-brown;
  font-size: 40px; /*48px*/
  margin-bottom: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.paragraph a {
  color: #262625;
}

.paragraph a:hover {
  color: #AACE8E;
}

.paragraph {
	width: 60vw;
}

img.hero {
  max-width: 40vw;
}

@media screen and (max-width: 480px) and (max-width: 960px){
  img.hero {
  max-width: 90vw;
}

  #title {
    font-size: 48px;
    line-height: 1em;
    margin-bottom: 0.5em;
  }

  body {
    height: 100%;
    min-height: 100vh;
    position: relative;
  }
}